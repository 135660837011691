<template>
  <b-row>
    <b-col cols="12">
      <div>
        <form-wizard color="#b9cde5" :title="null" :subtitle="null" shape="square"
          finish-button-text="Crear Planificación" back-button-text="Atras" next-button-text="Siguiente" class="mb-3"
          @on-complete="formSubmitted">
          <!-- detalles -->
          <tab-content title="Nombre planificación" :before-change="beforeNameTabSwitch">
            <BrunaForm ref="detailPlanificación" v-bind:fields="plan_detail.fields" :card="plan_detail.card">
            </BrunaForm>
          </tab-content>

          <!-- stock -->
          <tab-content title="Stock MMPP" :before-change="beforeStockTabSwitch">
            <b-row v-if="plan_detail.fields[1].value != null">
              <b-col>
                <ag-grid-table ref="stockMmpp" v-bind:configUrl="stock_config" v-bind:dataUrl="stock_data"
                  @selectionChanged="selectionStock" rowSelection="multiple"
                  >
                </ag-grid-table>

              </b-col>
            </b-row>
          </tab-content>

          <!-- demanda -->
          <tab-content title="Demanda">
            <b-row>
              <b-col>
                <ag-grid-table ref="demanda" :configUrl="demanda_config" v-bind:dataUrl="demanda_data"
                  @selectionChanged="selectionDemanda" rowSelection="multiple"
                  >
                </ag-grid-table>
              </b-col>
            </b-row>
          </tab-content>

          <!-- sku mmpp -->
          <tab-content title="SKU" :before-change="beforeSkuTabSwitch" >
            <ag-grid-table ref="sku" :configUrl="sku_config" :dataUrl="sku_data"
              v-bind:dataUrl="sku_data" @selectionChanged="selectionSku" rowSelection="multiple"
              >
            </ag-grid-table>

            <ag-grid-table ref="aditivos" :configUrl="aditivos_config" :dataUrl="aditivos_data"
              rowSelection="multiple"
              >
            </ag-grid-table>
          </tab-content>


          <!-- resumen -->
          <tab-content title="Resumen">
            <div>
              <b-row>
                <b-col>
                  <ag-grid-table ref="stockMmpp_r" :configUrl="stock_config" v-bind:dataUrl="stock_data"
                    @selectionChanged="selectionStock" rowSelection="multiple">
                  </ag-grid-table>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <ag-grid-table ref="demanda_r" :configUrl="demanda_config" v-bind:dataUrl="demanda_data"
                    @selectionChanged="selectionDemanda" rowSelection="multiple">
                  </ag-grid-table>
                </b-col>
              </b-row>
              
              <b-row>
                <b-col>
                  <ag-grid-table ref="sku_r" :configUrl="sku_config" :dataUrl="sku_data"
                    @selectionChanged="selectionSku" rowSelection="multiple">
                  </ag-grid-table>

                  <ag-grid-table ref="aditivos_r" :configUrl="aditivos_config" :dataUrl="aditivos_data" rowSelection="multiple">
                  </ag-grid-table>
                </b-col>
              </b-row>

            </div>

          </tab-content>
        </form-wizard>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
} from "bootstrap-vue";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";

import BrunaForm from "@/views/brunacomponents/BrunaForm.vue";
import AgGridTable from "@/views/brunacomponents/ag-grid-table/AgGridTable.vue";
import useApiServices from "@/services/useApiServices.js";

export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BrunaForm,
    AgGridTable,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],


  data() {
    return {

      semana: null,
      nombre: null,
      precios: [],
      demanda: [],
      demanda_config: useApiServices.demandaConfig,
      demanda_data: useApiServices.demandaData,
      // contrato_edit: useApiServices.ingresoContratosData,
      sku_config: useApiServices.restriccionesSkuConfig,
      sku_data: useApiServices.restriccionesSkuData,
      // sku_edit: useApiServices.restriccionesSkuEdit,
      stock_config: useApiServices.planificacionStockConfig,
      stock_data: useApiServices.planificacionStockData,
      // stock_edit: useApiServices.planificacionStockEdit,

      aditivos_config: useApiServices.aditivosConfig,
      aditivos_data: useApiServices.aditivosData,

      formatData: {
        stock_array: null,
        demanda_array: null,
        sku: null,
        nombre: null,
        semana: null,
        plan_user: 'Admin Pf'
      },

      formatDataTest: {
        plan_user: 'Admin',
        semana: null,
        nombre: null,
        sku_planificado: null,
        kg_planificados: null,
        costo_receta: null
      },

      plan_detail: {
        card: {
          title: "Detalles planificación",
          subtitle: "",
          tooltip: ""
        },
        fields: [
          {
            label: "Nombre planificacion",
            type: "input",
            id: "nombre",
            rules: "required",
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3,
            },
            value: null,
          },
          {
            label: "Semana",
            type: "select",
            id: "semana",
            rules: "required",
            options: [
              { value: null, text: "Selecciona una semana" },
              { value: 35, text: "semana 35" },
              { value: 36, text: "semana 36" },
              { value: 37, text: "semana 37" },
              { value: 38, text: "semana 38" },
              { value: 39, text: "semana 39" },
            ],
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3,
            },
            value: null,
          },
        ]
      },
    };
  },

  methods: {
    createOperaciones() {
      useApiServices
        .postPlanificacionCreate(this.formatDataTest, this)
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Carga Exitosa`,
              icon: "UploadIcon",
              variant: "success",
              html: `La operacion se ha guardado exitosamente`,
            },
          });
        })
        .catch((error) => {
          console.log(error);
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Ocurrio un Error`,
              icon: "UploadIcon",
              variant: "danger",
              html: `Ha ocurrido un error al guardar la nueva planificación</b>.`,
            },
          });
        });
    },
    save() {
      this.createOperaciones();
    },


    beforeNameTabSwitch() {
      if (!this.plan_detail.fields[1].value) {
        this.alertNext("Seleccionar una semana")
        return false;
      }
      this.formatDataTest.nombre = this.plan_detail.fields[0].value;
      this.formatDataTest.semana = this.plan_detail.fields[1].value;

      this.stock_data = useApiServices.planificacionStockData + "/" + this.formatDataTest.semana;
      this.stock_config = useApiServices.planificacionStockConfig + "/" + this.formatDataTest.semana;

      this.$refs.stockMmpp.refreshRows();
      //this.$refs.stockMmpp.configureGrid()

      return true;
    },

    beforeStockTabSwitch() {
      // if (!this.formatData.stock_array) {
      //   this.alertNext("Seleccionar una opcion del listado")
      //   return false;
      // }

      this.demanda_data = useApiServices.demandaData + "/" + this.formatDataTest.semana;

      this.$refs.demanda.refreshRows();
      return true;
    },

    beforeDemandaTabSwitch() {
      // if (!this.formatData.demanda_array) {
      //   this.alertNext("Seleccionar una opcion del listado")
      //   return false;
      // }
      return true;
    },
    beforeSkuTabSwitch() {
      // if (!this.formatData.sku) {
      //   this.alertNext("Seleccionar una opcion del listado")
      //   return false;
      // }
      this.$refs.stockMmpp_r.refreshRows();
      this.$refs.demanda_r.refreshRows();
      this.$refs.sku_r.refreshRows();
      return true;
    },

    selectionStock(rows) {
      this.formatData.stock_array = rows;
    },
    selectionDemanda(rows) {
      console.log(rows[0].meta_data);
      this.formatData.demanda_array = rows;
      this.formatDataTest.kg_planificados = rows[0].demanda;

    },
    selectionSku(rows) {
      this.formatData.sku = rows;
      this.formatDataTest.sku_planificado = rows.length;
    },

    formSubmitted() {
      this.save();
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Planificación iniciada con éxito, se notificará cuando se encuentre el plan óptimo",
          icon: "EditIcon",
          variant: "success",
        },
      });
    },

    alertNext(text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: text,
          icon: "EditIcon",
          variant: "danger",
        },
      });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.mb-5 {
  margin-bottom: 5%;
}
</style>